<template>
  <div class="view pa24">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
      <el-form-item label="开发区名称" prop="title">
        <el-input
            type="text"
            placeholder="请输入开发区名称"
            maxlength="50"
            v-model="ruleForm.title"
            style="width: 400px"
            show-word-limit
        >
        </el-input>
      </el-form-item>
      <el-form-item label="绑定会员" prop="memberId">
        <el-select
            v-model="ruleForm.memberId"
            placeholder="请选择会员"
            style="width: 400px"
        >
          <el-option
              v-for="item in memberList"
              :key="item.memberId"
              :label="item.userName"
              :value="item.memberId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input   style="width: 400px" clearable v-model="ruleForm.sort" min="0" @input="ruleForm.sort=ruleForm.sort.replace(/^0(0+)|[^\d]+/g,'')" maxlength="11" show-word-limit></el-input>
      </el-form-item>
      <el-form-item prop="coverImg" label="图片">
        <p class="ma mb10" style="color: rgb(150, 150, 150)">
          建议图片上传比例为1:1，支持格式png、jpg、jpeg，大小不超过1M
        </p>
        <div style="display: flex">
          <el-upload
              :action="uploadUrl"
              :class="{
              disabled: uploadDisabled,
            }"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :on-success="goodsUpload"
              :before-upload="beforeAvatarUploadImg"
              :file-list="ruleForm.coverImg"
              :limit="15"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog
              :visible.sync="dialogVisible"
              append-to-body
              :modal-append-to-body="false"
          >
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
      </el-form-item>
      <el-form-item label="地理位置" prop="address">
        <el-input
            id="suggestId"
            type="text"
            placeholder="请输入地址"
            v-model="ruleForm.address"
            maxlength="50"
            @change="theLocation"
            style="width: 400px"
            show-word-limit
        />
        <div
            id="baidumap"
            style="width: 400px; height: 400px; margin-top: 10px;display: none"
        ></div>
      </el-form-item>
      <el-form-item label="发布内容" prop="details">
        <!-- //富文本 -->
        <quill-editor
            class="ql-editor"
            style="height: 100%"
            v-model="ruleForm.details"
            ref="myQuillEditor"
            :options="editorOption"
            @ready="ready($event)"
            @change="onEditorChange($event)"
        />
        <span class="wordNumber">{{TiLength}}/10000</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="saveForm()">发布</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {mapState} from "vuex";

let ac;
import {addAttractInvestmentKFQ,updateAttractInvestmentKFQ,queryAttractInvestmentKFQById} from "@/api/chinaMerchants";
import {selectMemberPageList} from "@/api/members";
import { lineHeightStyle } from "@/utils/lineHeight.js";
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请输入品内容'
export default {
  name: "addFKQ",
  data() {
    return {
      memberList:[],
      TiLength:0,
      editorOption: quillConfig,
      ruleForm: {
        title:"",
        memberId: "",
        sort: "",
        address: "",
        details:"",
        coverImg:[],
      },
      fileList: [],
      rules: {
        title: [{ required: true, message: "请输入开发区名称" }],
        memberId: [{ required: true, message: "请选择选择分类" }],
        sort: [{ required: true, message: "请输入排序" }],
        details: [{ required: true, message: "请输入简介" }],
        coverImg: [{ required: true, message: "请上传图片" }],
      },
      radio: "",
      options: [],
      dialogImageUrl: "",
      dialogVisible: false,
      map: {}, //赋值地图
      local: {}, //搜索
      ossUrl: this.$store.state.ossUrl,
      uploadUrl: this.$store.state.uploadingUrl,
    };
  },
  computed: {
    ...mapState(['routerArr']),
    uploadDisabled() {
      return this.ruleForm.coverImg.length >= 15;
    },
  },
  created() {
    const { kfqId } = this.$route.query;
    if (kfqId) {
      this.queryDetails(kfqId);
    }
  },
  mounted() {
    this.createdMap();
    this.toMemberList();
  },
  beforeDestroy() {
    ac.removeEventListener("onConfirm", this.setValue);
  },
  methods: {
    toMemberList(){
      let ajax = {
        associationId: localStorage.getItem("associationId"),
        pageSize:9999,
        pageNum: 1,
        examineStatusList: '0,3',
        userStatus: 1,
      }
      selectMemberPageList(ajax)
          .then((res) => {
            this.centerDialogVisible = true
            if (res.data) {
              const { total, list } = res.data.pageInfo;
              this.memberList = list;
            }
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            });
          });
    },
    ready() {
      Quill.register({ 'formats/lineHeight': lineHeightStyle }, true);
    },
    onEditorChange(e){
      // e.quill.deleteText(1500,0);
      if(this.ruleForm.details==''){
        this.TiLength=0
      }else{
        this.TiLength=e.quill.getLength()-1
      }
    },
    /**@method 创建地图 */
    createdMap() {
      console.log('00')
      let navigationControl = new BMap.NavigationControl({
        // 靠左上角位置
        anchor: BMAP_ANCHOR_TOP_LEFT,
        // LARGE类型
        type: BMAP_NAVIGATION_CONTROL_LARGE,
        // 启用显示定位
        enableGeolocation: true,
      });
      this.map = new BMap.Map("baidumap");
      this.map.centerAndZoom("北京", 12);
      this.map.addControl(navigationControl);

      ac = new BMap.Autocomplete({
        //建立一个自动完成的对象
        input: "suggestId",
        location: this.map,
      });
      ac.addEventListener("onConfirm", this.setValue);
    },
    setValue(e) {
      const {
        item: { value },
      } = e;
      console.log(this.ruleForm.address )
      this.ruleForm.address = `${value.city}${value.district}${value.business}`;
    },
    handProgress(event, file, fileList) {
      this.$message({
        message: "正在上传",
      });
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = this.ossUrl + file.response.data
      this.fileList = fileList;
    },
    myFun() {
      console.log(this.local.getResults().getPoi(0))
      let pp = this.local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
      this.map.centerAndZoom(pp, 18);
      this.map.addOverlay(new BMap.Marker(pp)); //添加标注
      this.ruleForm.gps = JSON.stringify(pp);
    },
    theLocation(val) {
      this.map.clearOverlays(); //清除地图上所有覆盖物
      this.local = new BMap.LocalSearch(this.map, {
        //智能搜索
        onSearchComplete: this.myFun,
      });
      console.log(val)
      this.local.search(val);
    },
    /**@method 获取编辑数据 */
    async queryDetails(id) {
      const result = await queryAttractInvestmentKFQById({ kfqId:id });
      console.log(result)
      const {
        kfqName,
        kfqId,
        topImg,
        sort,
        address,
        memberId,
        kfqContent
      } = result.data;

      if (topImg) {
        this.ruleForm.coverImg = topImg.split(",").map((item, index) => {
          return {
            url: item,
            uid: index,
          };
        });
      }
      console.log(this.ruleForm.coverImg)

      this.kfqId = kfqId
      this.ruleForm.title = kfqName
      this.ruleForm.memberId = memberId
      this.ruleForm.sort = sort
      this.ruleForm.address = address
      this.ruleForm.details = kfqContent
    },
    /**@method 发布 */
    saveForm() {
      if(this.ruleForm.coverImg.length == 0){
        return this.$message({
          message: "图片未上传",
          type: "warning",
          center: true,
        });
      }
      let arr = this.ruleForm.coverImg.map((item) => item.url);
      let a = document.getElementById('suggestId').value;
      let data = {
        associationId: localStorage.getItem("associationId"),
        memberId:this.ruleForm.memberId,
        kfqName:this.ruleForm.title,
        topImg:arr.join(","),
        address:a,
        kfqContent:this.ruleForm.details,
        sort:this.ruleForm.sort,
        isDel:0
      };
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.kfqId) {
            //保存编辑
            await updateAttractInvestmentKFQ(
                { ...data, kfqId:this.kfqId },
                { showLoading: true }
            );
          } else {
            //添加请求
            await addAttractInvestmentKFQ(data, { showLoading: true });
          }
          this.$router.push({ path: "/developmentZone" });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeAvatarUploadImg(file){
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1M!");
      }
      return isLt2M;
    },
    handleRemove(file, fileList) {
      this.ruleForm.coverImg = fileList;
    },
    //图片
    goodsUpload(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.ruleForm.coverImg = arr;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

